import Icon from '@gameonsports/components/cjs/Icon'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { Formik } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { navigate } from '../../../components/Router/Router'
import HomeSearchForm, {
  HomeSearchFormValidationSchema,
  HomeSearchFormValues,
} from '../../../forms/HomeSearchForm'
import { getLocale } from '../../../utils/env'
import {
  BannerContainer,
  BannerText,
  BottomBannerText,
  ContrastText,
  CourtBox,
  FooterText,
  FullWidthStack,
  HeroText,
  HomeLink,
  Image,
  PageBody,
  SportLink,
  SportTileContainer,
  WideStack,
} from '../Home.styles'
import { SportsLocale, getAvailableSports } from '../Home.utils'
import changingTheGame from '../images/changing-the-game.jpeg'
import everysport from '../images/everysport.jpeg'
import { SportCard } from './SportCard'

export const HomeUK = () => {
  const sports = getAvailableSports(SportsLocale.UK)
  const { links } = getLocale()

  return (
    <>
      <Helmet
        title="UK Sports League Management, Registration & Scheduling"
        meta={[
          {
            name: 'description',
            content:
              'PlayHQ provides easy sports league management, team registration, and scheduling for UK governing bodies, leagues and clubs. Discover seamless online tools for organisers, clubs, and players.',
          },
        ]}
      />
      <PageBody
        gap="xxxl"
        paddingX="m"
        paddingTop="m"
        paddingBottom="xl"
        marginX="auto"
      >
        <FullWidthStack gap="xl" paddingY="xl" justifyContent="center">
          <Stack gap="m" paddingTop="xl" paddingBottom="l">
            <HeroText>
              Your <ContrastText>sport</ContrastText> united.
            </HeroText>
          </Stack>

          <Formik<HomeSearchFormValues>
            initialValues={{
              query: '',
            }}
            onSubmit={values => {
              return navigate(`/search?query=${values.query}`)
            }}
            validationSchema={HomeSearchFormValidationSchema}
          >
            {formProps => (
              <form onSubmit={formProps.handleSubmit} action="/search">
                <HomeSearchForm {...formProps} />
              </form>
            )}
          </Formik>
        </FullWidthStack>

        <Stack gap="xxl" paddingY="xxxl">
          <Text as="h2" size="36" family="Athletics-Black">
            <Text color="grape400">Find</Text> your Organisation
          </Text>
          <SportTileContainer $columnCount={sports.length}>
            {sports.map(sport => (
              <SportLink
                key={sport.name}
                to={sport.link}
                $sportsCount={sports.length}
              >
                <SportCard
                  name={sport.name}
                  image={sport.image}
                  image2x={sport.image2x}
                />
              </SportLink>
            ))}
          </SportTileContainer>
        </Stack>
      </PageBody>

      {/* Banners */}
      <Stack backgroundColor="black400" gap="xxxl" paddingY="xxxl" as="section">
        <div>
          <BannerContainer
            gap="l"
            justifyContent="space-between"
            padding="m"
            marginX="auto"
          >
            <Image src={changingTheGame} />
            <BannerText gap="m" alignItems="flex-start">
              <Text as="h2" color="white400" family="Athletics-Black" size="32">
                <Text color="watermelon400">Changing</Text> the game.
              </Text>
              <Text as="p" color="white400">
                PlayHQ is on a mission to reinvent community sport technology,
                replacing decades of unreliable legacy systems and spreadsheets
                with a seamless, mobile-first digital experience that connects
                communities across sporting codes.
              </Text>
              <HomeLink href={links.aboutUs} rel="noopener noreferrer">
                Find out more
              </HomeLink>
            </BannerText>
          </BannerContainer>
        </div>

        <div>
          <BannerContainer
            gap="l"
            justifyContent="space-between"
            padding="m"
            marginX="auto"
          >
            <Image src={everysport} $order={1} />
            <BottomBannerText gap="m" alignItems="flex-start">
              <Text as="h2" color="white400" family="Athletics-Black" size="32">
                One platform for <Text color="watermelon400">every sport.</Text>
              </Text>
              <Text as="p" color="white400">
                Capable of handling thousands of games and millions of players
                at a time, PlayHQ leverages powerful integrations, intuitive
                design and rich data to create an all-in-one software solution
                that streamlines every aspect of community sport administration.
              </Text>
              <HomeLink href={links.phqHome} rel="noopener noreferrer">
                See how it works
              </HomeLink>
            </BottomBannerText>
          </BannerContainer>
        </div>
      </Stack>

      <CourtBox>
        <WideStack gap="xl" marginX="auto" alignItems="flex-start">
          <FooterText color="white400" size="36">
            Join the ranks of World's biggest and best sporting organisations.
          </FooterText>
          <HomeLink href={links.sales} rel="noopener noreferrer">
            Get PlayHQ today
            <Icon name="right-arrow-strong" color="white400" />
          </HomeLink>
        </WideStack>
      </CourtBox>
    </>
  )
}
